//toolbar

.toolbar {
  background-color: $white;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;

  .toolbar-container {
    nav {
      line-height: 1;

      ul {
        display: flex;
        padding: 0;
        margin: 0;
        align-items: center;

        > li {
          list-style: none;

          a {
            color: $body-color;
            display: inline-block;

            i {
              font-size: 1.375rem;
              line-height: 0;
            }
          }
        }
      }

      .sidebar-toggler {
        .toggle-btn {
          height: 30px;
          width: 30px;
          position: relative;
          &:before,
          &:after {
            content: "";
            width: 100%;
            height: 2px;
            background-color: currentColor;
            display: block;
            position: absolute;
            border-radius: 2px;
            transform-origin: center center;
            transition: transform 1250ms cubic-bezier(0.19, 1, 0.22, 1),
              width 1250ms cubic-bezier(0.19, 1, 0.22, 1);
          }

          &:after {
            right: 0;
            top: 10px;
          }

          span {
            display: block;
            position: absolute;
            bottom: 0;
            font-weight: 500;
            text-transform: uppercase;
            @include font-size(0.625rem);
            letter-spacing: 0.5px;
            transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
          }
        }

        &:not(.in) {
          .toggle-btn {
            &:hover {
              span {
                color: $headings-color;
              }
              &:before {
                width: 20px;
              }
            }
          }
        }

        &.in {
          .toggle-btn {
            &:before,
            &:after {
              top: calc(50% - 1px);
            }

            &:before {
              transform: rotate(45deg);
            }

            &:after {
              transform: rotate(-45deg);
            }

            span {
              bottom: -100%;
              opacity: 0;
            }
          }
        }
      }

      .user-profile {
        > a {
          height: 36px;
          width: 36px;
          position: relative;

          img {
            border-radius: 50rem;
          }

          &:after {
            height: 12px;
            width: 12px;
            content: "";
            background-color: $danger;
            border-radius: 50rem;
            position: absolute;
            right: -5px;
            top: calc(50% - 6px);
            border: 2px solid $white;
          }
        }

        &.active a:after {
          background-color: $success;
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    border-right: 1px solid $border-color;
    width: 65px;
    height: 100vh;

    .toolbar-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: $grid-gutter-width/2 + 0.5 $grid-gutter-width/2;
      height: 100%;

      nav ul {
        flex-direction: column;
        > li {
          & + li {
            margin-top: 20px;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(xl) {
    right: 0;
    box-shadow: 0 0 20px rgba($black, 0.2);
    .toolbar-container {
      display: flex;
      align-items: center;
      padding: $grid-gutter-width/2 + 0.25 $grid-gutter-width/2;
      justify-content: space-between;
      nav ul {
        > li {
          & + li {
            margin-left: 20px;
          }
        }
      }
    }
  }
}

.sidebar {
  border-right: 1px solid $border-color;
  width: 270px;
  background-color: $white;
  height: 100vh;
  position: fixed;
  transition: left 500ms cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 99;
  .sidebar-container {
    padding: $grid-gutter-width;

    .sidebar-header {
      .navbar-brand {
        padding: 0;
      }

      .data-usage {
        .progress {
          height: 0.5rem;
          @include font-size(0.625rem);
          background-color: darken($body-bg, 1.5%);
          border-radius: 50rem;
          .progress-bar {
            border-radius: 50rem;
            span {
              position: absolute;
              right: 0;
              top: 0;
              color: $primary;
              @include font-size(0.75rem);
            }
          }

          & + .progress {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

/*-----------------------------------------
Navigation Styles
-----------------------------------------*/

.sidebar-body {
  margin-top: $grid-gutter-width;
  nav {
    padding: 0;
    .navbar-nav {
      padding: 0;
      font-family: $navigation-font-family;
      .menu-title {
        @include font-size(0.75rem);
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.5px;
        color: $text-muted;
      }
      .nav-item {
        list-style: none;
        font-size: 0;
        &+.menu-title{
          padding-top: $grid-gutter-width/2;
        }
        > .nav-link {
          position: relative;
          font-weight: $navigation-font-weight;
          font-size: $navigation-font-size;
          letter-spacing: $navigation-letter-spacing;
          color: $headings-color;
          padding: 10px 0;
          display: flex;
          align-items: center;
          i {
            @include font-size(1.125rem);
            width: 18px;
            margin-right: 15px;
            color: $body-color;
            line-height: 19px;
          }

          &.collapsed {
            &:after {
              transform: rotate(0);
            }
          }
        }

        &.dropdown {
          > a:after {
            content: "";
            background-image: str-replace(
              url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='512' height='512' viewBox='0 0 512 512'%3E%3Ctitle%3Eionicons-v5-a%3C/title%3E%3Cpolyline points='112 184 256 328 400 184' style='fill:none;stroke:#{$body-color};stroke-linecap:round;stroke-linejoin:round;stroke-width:48px'/%3E%3C/svg%3E"),
              "#",
              "%23"
            );
            border: 0;
            background-size: 100%;
            width: 0.875rem;
            height: 0.875rem;
            line-height: 0;
            margin-left: auto;
            transform: rotate(180deg);
            transition: 0.3s ease transform;
          }
          > .nav-link {
            &:not(.collapsed) {
              color: $primary;
              i {
                color: $primary;
              }
            }
          }
        }
      }

      .sub-nav {
        padding-left: 0;
        padding-bottom: 10px;
        margin: 0;
        .dropdown-item {
          list-style: none;
          padding: 0;

          .dropdown-link {
            display: block;
            padding-top: 5px;
            padding-bottom: 5px;
            clear: both;
            font-weight: $font-weight-normal;
            color: $dropdown-link-color;
            white-space: normal;
            text-decoration: none;
            @include font-size(0.8375rem);
            position: relative;
            &:before {
              width: 18px;
              display: inline-block;
              content: "\f28a";
              font-family: "bootstrap-icons";
              margin-right: 15px;
              @include font-size(0.375rem);
              text-align: center;
              font-weight: bold;
            }
            &:hover,
            &:focus {
              background-color: transparent;
              text-decoration: none;
              color: $dropdown-link-hover-color;
              &:before {
                color: $primary;
                content: "\f287";
              }
            }
          }
          &:hover,
          &:focus {
            background-color: transparent;
            color: inherit;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    &.navbar > .nav-item > .nav-link {
      padding: 0 10px;
    }
  }
}

/*-----------------------------------------
* Right navigation style
*-----------------------------------------*/

.right-nav {
  .navbar-nav .nav-item .nav-link {
    padding: 0 8px;
    i {
      @include font-size($header-icon-font-size);
      line-height: 1;
    }
  }
  &.icons-nav > .navbar-nav {
    flex-direction: row;
  }
}

/*-----------------------------------------
Navbar Toggler Style
------------------------------------------*/

.navbar-toggler {
  padding: 0;
  border: 0;
  order: 3;
  margin-left: 20px;
  transition: 0.3s linear all;
  &:focus {
    outline: none;
  }
  .custom-navigation-icon i {
    &:first-child,
    &:last-child {
      left: 8px;
    }
  }
  &.collapsed .custom-navigation-icon i {
    &:first-child,
    &:last-child {
      left: 0;
    }
  }
}

/*-----------------------------------------
Dropdown Menu Styles
-----------------------------------------*/

.dropdown-menu {
  .dropdown-item {
    padding: 0;
    &:hover,
    &:focus {
      background-color: transparent;
    }
    > a {
      display: block;
      padding-top: $dropdown-item-padding-y;
      padding-bottom: $dropdown-item-padding-y;
      clear: both;
      font-weight: $font-weight-normal;
      color: $dropdown-link-color;
      white-space: normal;
      line-height: 1.7;
      @include font-size(0.8375rem);
      position: relative;
      text-decoration: none;
      &:hover,
      &:focus {
        background-color: transparent;
        color: $dropdown-link-hover-color;
      }
    }
    &.dropdown:hover > a {
      color: $dropdown-link-hover-color;
    }
  }
  h4.dropdown-header {
    padding: $dropdown-item-padding-y 0;
    letter-spacing: 1px;
    @include font-size($font-size-base);
  }
  &.show {
    transition: 0.3s linear all 300ms;
  }
}

.sidebar {
  &.dark {
    background-color: #181c32;
    .sidebar-header {
      .data-usage {
        .progress {
          background-color: rgba($white, 0.1);
        }
        .progress-title {
          .text-bold {
            color: $white;
          }
        }
      }
    }
    .sidebar-body {
      nav {
        .navbar-nav {
          .nav-item {
            > .nav-link {
              color: #adb1c7;
              &:hover {
                color: lighten(#adb1c7, 10%);
              }
            }
          }
          .sub-nav {
            .dropdown-item {
              .dropdown-link {
                &:hover {
                  color: #adb1c7;
                }
              }
            }
          }
        }
      }
      &.ps {
        padding-right: 15px;
        margin-right: -15px;
        .ps__thumb-y {
          background-color: rgba($white, 0.1);
        }
      }
    }
  }
}

.toolbar {
  &.dark {
    background-color: #181c32;
    border-right-color: rgba($white, 0.075);
    .toolbar-container {
      nav {
        .sidebar-toggler:not(.in) .toggle-btn:hover span {
          color: $primary;
        }
      }
    }
  }
}

//page-wrapper
.page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  transition: margin 500ms cubic-bezier(0.19, 1, 0.22, 1),
    width 500ms cubic-bezier(0.19, 1, 0.22, 1);
  .page-content {
    min-height: calc(100vh - 250px);
    padding-top: $grid-gutter-width;
    @include media-breakpoint-up(lg){
      padding: $grid-gutter-width;
      padding-bottom: 0;
    }
  }
}

//sidebar active
.contain-wrapper {
  &:not(.sidebar-active) {
    @include media-breakpoint-up(xl) {
      .page-wrapper {
        width: calc(100% - 65px);
        margin-left: 65px;
      }
      .sidebar {
        left: -205px;
      }
    }

    @include media-breakpoint-down(xl) {
      padding-top: 70px;
      .sidebar {
        left: -270px;
      }
    }
  }

  &.sidebar-active{
    @include media-breakpoint-up(xl){
      .page-wrapper{
        width: calc(100% - 335px);
        margin-left: 335px;
        overflow: hidden;
      }
      .sidebar {
        left: 65px;
      }
    }
    @include media-breakpoint-down(xl){
      .sidebar {
        left: 0;
      }
    }
  }

  @include media-breakpoint-down(xl) {
    padding-top: 68px;
  }
}
