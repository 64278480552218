/*Content Search*/

.content-search{
    form {
        position: relative;

        .form-control {
            padding-left: 20px;
            border: 0;
            appearance: none;
            font-weight: 500;
            box-shadow: 0 0 30px rgba($black, .05);
            border-radius: 2px;
            &::placeholder{
                color: $primary;
            }

            &:focus {
                border-color: $primary;
                box-shadow: 0 0 30px rgba($primary, .15);
            }
        }

        button {
            position: absolute;
            padding: 0;
            right: $input-padding-x;
            top: 50%;
            transform: translateY(-50%);
            background-color: transparent;
            box-shadow: none;
            border: 0;
            @include font-size(1.25rem);
            color: $primary;
            display: inline-block;
            cursor: pointer;

            &:focus,
            &:hover {
                outline: none;
            }
        }
    }
}