/* swiper slider custom style */

.swiper-carousel-outer {
  position: relative;
  [class^="swiper-container-"],
  [class*=" swiper-container-"] {
    overflow: hidden;
  }
}

/* swiper navigation style */

.swiper-navigation {
  pointer-events: none;
  > * {
    position: static;
    margin: 0;
  }
  .swiper-button-prev,
  .swiper-button-next {
    background-image: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 900ms cubic-bezier(0.19, 1, 0.22, 1);
    pointer-events: auto;
    cursor: pointer;
    margin: 0;
    &.small {
      height: 40px;
      width: 40px;
      font-size: 1rem;
    }
    &.medium {
      height: 50px;
      width: 50px;
      font-size: 1.125rem;
    }
    &.large {
      height: 65px;
      width: 65px;
      font-size: 1.5rem;
    }
    &.outline-thin,
    &.outline-bold {
      border-style: solid;
    }
    &.outline-thin {
      border-width: 1px;
    }
    &.outline-bold {
      border-width: 2px;
    }
    &.arrow-light {
      color: $white;
      border-color: $white;
    }
    &.arrow-dark {
      color: $gray-900;
      border-color: $gray-900;
    }
    &.radius {
      border-radius: 50%;
    }
    &:hover,
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: $primary;
      border-color: $primary;
      i {
        animation: arrowTranslateX ease 0.4s alternate;
      }
    }
  }
  .swiper-button-prev {
    left: 15px;
    &:hover {
      i {
        animation-direction: alternate-reverse;
      }
    }
  }
  .swiper-button-next {
    right: 15px;
  }

  @include media-breakpoint-down(sm) {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    .swiper-button-next {
      margin-left: 10px;
    }
  }

  &.overly-middle {
    @include media-breakpoint-up(md) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
      right: 15px;
      z-index: 9;
      display: flex;
      justify-content: space-between;
    }
  }
}

/* swiper Pagination style */

.swiper-pagination {
  .swiper-pagination-bullet {
    height: 10px;
    width: 10px;
    display: inline-block;
    border-radius: 50rem;
    opacity: 1;
    transition: 0.2s ease;
    vertical-align: middle;
    border: 3px solid transparent;
    background-color: rgba($primary, 0.24);
    &.swiper-pagination-bullet-active {
      width: 18px;
      height: 18px;
      border-color: $primary;
      background-color: transparent;
    }
    &:hover,
    &:focus {
      outline: none;
    }
  }
  &.pagination-light {
    .swiper-pagination-bullet {
      background-color: rgba($white, 0.6);
      &.swiper-pagination-bullet-active {
        border-color: $white;
        background-color: transparent;
      }
    }
  }
}
.swiper-container-horizontal > .swiper-pagination {
  .swiper-pagination-bullet {
    margin: 0 4px;
  }
}

/* swiper Pagination Fraction style */

.swiper-pagination-fraction {
  text-shadow: 3px 4px 5px rgba(0, 0, 0, 0.75);
  color: darken($white, 8%);
  line-height: 1;
  .swiper-pagination-current {
    font-size: 2rem;
    color: $white;
    font-weight: bold;
  }
  .swiper-pagination-total {
    font-size: 1.125rem;
    font-weight: 500;
  }
}

/* swiper Numeric Pagination style */

.swiper-numeric-pagination {
  position: absolute;
  right: 30px;
  left: 30px;
  bottom: 30px;
  z-index: 9;
  display: inline-block;
  pointer-events: none;
  width: initial;
  .swiper-pagination-bullet {
    width: initial;
    height: initial;
    border-radius: none;
    background: transparent;
    opacity: 1;
    line-height: 1;
    font-size: 0.875rem;
    font-weight: 500;
    pointer-events: auto;
    opacity: 0.74;
    transition: 0.2s linear all;
    &:nth-child(-n + 9) {
      &:before {
        content: "0";
      }
    }
    & + .swiper-pagination-bullet {
      margin-left: 8px;
    }
    &.swiper-pagination-bullet-active {
      font-size: 1.75rem;
      opacity: 1;
    }
    &:hover,
    &:focus {
      outline: none;
    }
  }
  &.pagination-dark {
    .swiper-pagination-bullet {
      color: $gray-900;
    }
  }
  &.pagination-light {
    .swiper-pagination-bullet {
      color: $white;
    }
  }
}

@keyframes arrowTranslateX {
  from {
    transform: translateX(0);
  }
  33% {
    transform: translateX(100%);
    opacity: 0;
  }
  67% {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
