/*Style for Croppie*/
.thumbinal-image{
    cursor: pointer;
    figure{
        background: $white;
        border: 1px solid $border-color;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
        z-index: 1;
        &:before{
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            content: "\f40a";
            font-family: 'bootstrap-icons';
            font-size: clamp(10rem, 100vw, 4rem);
            display: flex;
            align-items: center;
            line-height: 1;
            justify-content: center;
            opacity: 0.15;
            color: $primary;
            z-index: -1;
        }
        figcaption{
            position: absolute;
            bottom: $grid-gutter-width/2;
            right: $grid-gutter-width/2 + .375;
            display: inline-block;
            @include font-size(2rem);
            i{
                line-height: 0;
            }

        }
    }

    &:hover{
        figure{
            figcaption{
                color: $headings-color;
            }
        }

    }

}

#cropImagePop{
    .modal-dialog{
        max-width: 100%;
        border: 0;
        margin: 0;
        height: 100%;
    }
    .modal-content{
        background-color: transparent;
        border: 0;
        height: 100%;
        .modal-body{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            .cr-slider-wrap{
                margin-top: 40px;
                margin-bottom: 40px;
            }
            input[type=range]::-webkit-slider-runnable-track {
                background: rgba($white, .12);
                border-radius: 3px;
              }
              input[type=range]::-webkit-slider-thumb {
                  cursor: pointer;
              }
        }
    }

    #cropImageBtn{
        position: absolute;
        bottom:20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        background-color: $white;
        border-radius: 50rem;
        color: $primary;
        border: 0;
        font-weight: 600;
        padding: 5px 35px;
    }

}

#upload-demo{
    height: 200px;
    width: 100%;
}

.modal-backdrop{
    background-color: darken($primary, 45%);
    &.show{
        opacity: 0.94;
    }
}