/* 404 page not found Page Style*/

.coming-soon {
  text-align: center;

  .image-overlay {
    animation: zoomOut 20s linear infinite alternate-reverse;
  }

  .content-wrapper {
    padding: 25px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    position: relative;

    >* {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }

  h1 {
    letter-spacing: 5px;
    margin: 0;
    @include font-size(4rem);
  }

  h2 {
    letter-spacing: 1px;
  }

  .social-media {
    a {
      color: $white;
    }

    &:not(.outline):not(.brand-color-bg) a {
      font-size: 1.75rem;
      &:hover{
        color: $white;
      }
    }
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

@keyframes glitch {

  2%,
  64% {
    transform: translate(2px, 0) skew(0deg);
  }

  4%,
  60% {
    transform: translate(-2px, 0) skew(0deg);
  }

  62% {
    transform: translate(0, 0) skew(5deg);
  }
}

@keyframes glitchTop {

  2%,
  64% {
    transform: translate(2px, -2px);
  }

  4%,
  60% {
    transform: translate(-2px, 2px);
  }

  62% {
    transform: translate(13px, -1px) skew(-13deg);
  }
}

@keyframes glitchBotom {

  2%,
  64% {
    transform: translate(-2px, 0);
  }

  4%,
  60% {
    transform: translate(-2px, 0);
  }

  62% {
    transform: translate(-22px, 5px) skew(21deg);
  }
}