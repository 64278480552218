/* Progress Bar styles */

.progressbar {
    .item{
        position: relative;
        padding-top: 30px;
        &+.item{
            margin-top: $grid-gutter-width;
        }
        .progress-title {
            display: block;
            margin-top: 10px;
            font-size: 0.75rem;
            font-weight: 500;
            line-height: 1.2;
            text-transform: capitalize;
            color: #798ca3;
            .text-bold {
                color: #031019;
                font-weight: bold;
                font-size: 0.9375rem;
                display: inline-block;
                margin-right: 5px;
            }
  
        }
    }
}

