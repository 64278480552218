/* 404 page not found Page Style*/

.page-not-found {
  text-align: center;
  .image-overlay{
    animation: zoomOut 20s linear infinite alternate-reverse;
  }
  h1 {
    letter-spacing: 5px;
    margin: 0;
    span {
      display: inline-block;
      animation: glitch 1s linear infinite;

      &:before,
      &:after {
        content: attr(title);
        position: absolute;
        left: 0;
      }

      &:before {
        animation: glitchTop 1s linear infinite;
        clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
      }

      &:after {
        animation: glitchBotom 1.5s linear infinite;
        clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
      }
    }
  }
  h2{
      letter-spacing: 1px;
  }
}

@include media-breakpoint-up(md){
    .page-not-found {
        h1{            
            font-size: 7rem;
        }
        h2{
            font-size: 1.75rem;
        }

    }

}

@include media-breakpoint-down(sm){
    .page-not-found {
        h1{            
            font-size: 5rem;
        }
        h2{
            font-size: 1.5rem;
        }

    }

}

@keyframes zoomOut{
    0%{
        transform: scale(1)
    }
    100%{
        transform: scale(1.1)
    }
}

@keyframes glitch {
  2%,
  64% {
    transform: translate(2px, 0) skew(0deg);
  }
  4%,
  60% {
    transform: translate(-2px, 0) skew(0deg);
  }
  62% {
    transform: translate(0, 0) skew(5deg);
  }
}

@keyframes glitchTop {
  2%,
  64% {
    transform: translate(2px, -2px);
  }
  4%,
  60% {
    transform: translate(-2px, 2px);
  }
  62% {
    transform: translate(13px, -1px) skew(-13deg);
  }
}

@keyframes glitchBotom {
  2%,
  64% {
    transform: translate(-2px, 0);
  }
  4%,
  60% {
    transform: translate(-2px, 0);
  }
  62% {
    transform: translate(-22px, 5px) skew(21deg);
  }
}
