/* partner  block style */
.card {
  background: transparent;
  &:not(.has-border),
  &:not(.has-shadow) {
    border: 0;
    box-shadow: none;
  }

  .card-header {
    padding: 0 0 $spacer;
  }

  &.has-border,
  &.has-shadow,
  &[class^="bg-"],
  &[class*=" bg-"] {
    border-radius: 4px;
    .card-header {
      padding: $spacer;
      &:not(.divider) {
        padding-bottom: 0;
      }
    }

    .card-body {
      padding: $spacer;
    }
  }

  &.has-border {
    border: 1px solid $border-color;
  }

  &.has-shadow {
    box-shadow: 0 0 24px rgba($body-color, 0.1);
  }

  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    &:not(.has-border) {
      border-radius: 0;
      border: 0;
    }

    &.has-border {
      padding-bottom: $grid-gutter-width/2;
      border-bottom: 1px solid $border-color;
    }

    &[class^="bg-"],
    &[class*=" bg-"] {
      padding-bottom: $grid-gutter-width/2;
    }

    .card-title {
      margin: 0;
      letter-spacing: 0.5px;
      p {
        @include font-size(0.9375rem);
      }
      h2 {
        @include font-size(1.25rem);
        margin: 0;
      }
      h3 {
        @include font-size(1rem);
        margin: 0;
      }
    }

    h3.card-title {
      @include font-size(1rem);
    }
    h4.card-title {
      @include font-size(0.9375rem);
    }
    h5.card-title {
      @include font-size(0.875rem);
    }
    h2.card-title {
      @include font-size(1.25rem);
    }

    .dropdown {
      @include font-size(0);
      > a {
        @include font-size(1.375rem);
        color: $body-color;
        display: inline-flex;
        align-items: center;
        line-height: 1;
        &.show,
        &:hover,
        &:focus {
          color: $primary;
        }
      }

      .dropdown-menu {
        padding: $grid-gutter-width/2;
        border-radius: 4px;
        border: 1px solid $border-color;
        min-width: 10rem;
        display: block;
        margin-top: 0;
        visibility: hidden;
        opacity: 0;
        transition: 0.3s ease opacity;
        .dropdown-item {
          @include font-size(0.8375rem);
          display: flex;
          align-items: center;
          padding: 6px 0;
          line-height: 1;
          letter-spacing: 0.3px;
          i {
            @include font-size(0.9375rem);
            margin-right: 10px;
          }

          &:hover,
          &:focus {
            color: $primary;
          }
        }

        &.show {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  .card-body {
    padding: 0;
  }
}

//card module
.card {
  &.module {
    position: relative;
    min-width: 310px;

    .badge {
      background-color: $white;
      border-radius: 50rem;
    }
    .icon {
      font-size: 7rem;
      position: absolute;
      right: $spacer;
      line-height: 0;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0.075;
      pointer-events: none;
    }

    .card-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        margin: 5px 0;
      }
      .meta {
        padding: 0;
        list-style: none;
        > li {
          & + li {
            margin-top: 10px;
          }
        }
      }
      .date {
        @include font-size(0.75rem);
        opacity: 0.75;
        padding-top: 10px;
        i {
          line-height: 0;
          vertical-align: middle;
          margin-right: 10px;
          font-weight: bold;
          @include font-size(1rem);
        }
      }
      .btn-add-new {
        @include font-size(3rem);
        height: 45px;
        width: 45px;
        line-height: 0;
        border-radius: 50rem;
        background: rgba($white, 0.84);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        &:before {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          content: "";
          background-color: $white;
          border-radius: 50rem;
          z-index: -1;
          transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
        }

        &:hover {
          &:before {
            transform: scale(1.5);
            opacity: 0;
          }
        }
      }
    }

    @each $color, $value in $theme-colors {
      &.bg-light-#{$color} {
        background-color: rgba($value, 0.075);
        color: darken($value, 45%);
        a {
          color: darken($value, 45%);
          text-decoration: none;
        }
        .card-body {
          .btn-add-new {
            color: $value;
          }
          .badge {
            color: $value;
          }
        }
      }
    }
  }
}

//social media
.social-info {
 
  .card {
    @each $color, $value in $theme-colors {
      &.bg-light-#{$color} {
        background-color: rgba($value, 0.15);
        i {
          color: $value;
        }
        a {
          color: darken($value, 45%);
          text-decoration: none;
        }
        .card-body {
          .btn-add-new {
            color: $value;
          }
          .badge {
            color: $value;
          }
        }
      }
    }
  }
  ul{
    display: flex;
    margin: $grid-gutter-width/-2;
    flex-wrap: wrap;
    padding: 0;
    >li{
      padding: $grid-gutter-width/2;
      list-style: none;
      overflow: hidden;
    }
  }
  .card{
    padding: $grid-gutter-width;
    padding-left: 80px;
    @include font-size(.875rem);
    white-space: nowrap;
    i{
      position: absolute;
      left: $grid-gutter-width;
      top: 50%;
      @include font-size(2.5rem);
      transform: translateY(-50%);
      line-height: 0;
    }
    h5{
      margin: 0;
    }
  }

  
  @include media-breakpoint-up(sm){
    ul{
      align-items: center;
      justify-content: center;
      >li{
        width: 50%;
        flex: 0 0 50%;
      }
    }

  }
  @include media-breakpoint-down(sm){
    ul{
      flex-direction: column;
      >li{
        width: 100%;
        flex: 0 0 100%;
      }
    }

  }

}


.settings-list{
  line-height: 1.3;
  background-color: $white;
  padding: $grid-gutter-width 0;
  @include font-size(.8375rem);
  h4{
    @include font-size(.9375rem);
    margin: 0;
    text-transform: capitalize;
  }
  a{
    color: $headings-color;
    text-decoration: none;
  }
  .small{
    @include font-size(.8375rem);
    display: block;

  }
  .badge {
    font-weight: 500;
    font-style: normal;
    padding: 0.375rem 0.9375rem;
    @include font-size(0.75rem);
    @each $color, $value in $theme-colors {
      &.bg-light-#{$color} {
        background-color: rgba($value, 0.2);
        color: $value;
      }
    }
  }
  .action{
    .btn-link{
      @include font-size(1.125rem);
      outline: none;
      box-shadow: none;      
      &.edit{
        color: $primary;
      }
      &.delete{
        color: $danger;
      }
      &+.btn-link{
        margin-left: 10px;
      }
    }
  }
  ul{
    padding: 0;
    margin: 0;
    >li{
      list-style: none;
      display: flex;
      align-items: center;
      padding: $grid-gutter-width/4;
      border-radius: 2px;
      flex-wrap: wrap;
      &+li{
        border-top: 1px solid $border-color;
      }
      >div{
        padding: $grid-gutter-width/2;
        &:last-child{
          text-align: right;
        }
        &.update{
          text-align: right;
        }
      }
    }
  }

  .field-wrapper{
    display: flex;
    align-items: center;
    .form-control{
      border-right-color: transparent;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
    .btn-primary.light{
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      display: inline-flex;
      font-weight: 500;
      i{
        margin-right: 5px;
      }
  }

  }


}
