//director message

.director-message{
    margin: 0;
    font-size: 1.125rem;
    border-radius: 2px;
    padding: 30px;

    &.shadow{
        box-shadow: 0px 0px 10px 0px rgba($primary, 0.1);
    }


    .director-message-footer{
        position: relative;
        padding-left: 75px;
        .intro-img{
            height: 60px;
            width: 60px;
            position: absolute;
            left: 0;
            top: calc(50% - 30px);
            overflow: hidden;
            border-radius: 50%;
        }
        h3{
            font-size: 1.125rem;
            margin: 0 0 7px;
        }

        h5{
            font-size: .875rem;
            opacity: 0.75;
            font-weight: 500;
        }

    }
    @include media-breakpoint-up(md){
        padding: 45px;
    }

    @include media-breakpoint-up(lg){
        padding: 60px;
    }

}

