
//custom tabs
.custom-tab{
    >.ant-tabs-nav{
        position: relative;
        &:before{
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            height: 3px;            
            background: darken($border-color, 5%);
            content: '';
        }

        &:after{
            bottom: 1px;
        }
       
        .ant-tabs-tab{
            font-weight: 500;
            @include font-size(.9375rem);
            padding-top: 10px;
            padding-bottom: 10px;
            color: $body-color;

        }
    }
  
    .ant-tabs-content-holder{
        padding-top: 15px;
    }
  }
  