/* footer styles */
#footer {
  background-color: $footer-bg;
  color: $footer-text-color;
  position: relative;
  font-weight: 300;
  line-height: 1.75;
  z-index: 1;
  font-size: 0.875rem;
  letter-spacing: 0.2px;
  padding: $grid-gutter-width 0;
  
  @include media-breakpoint-up(lg){
    padding: $grid-gutter-width;

  }
  a {
    text-decoration-color: $footer-link-color;
    color: $footer-link-color;
    font-weight: 500;
  }
  .footer-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    >*{
      @include media-breakpoint-up(lg){
        &:last-child{
          text-align: right;
        }
      }
    }
    @include media-breakpoint-down(md){
      flex-direction: column;
      >.col-6{
        width: 100%;
      }
    }
  }
}

/* Newsletter styles */
.newsletter {
  display: block;
  h4 {
    margin: 0;
    padding-right: 10px;
    color: $footer-headings-color;
  }

  form {
    display: flex;
    align-items: flex-end;
  }

  .form-group {
    flex: 1 1 auto;
    margin: 0;
    &:last-child {
      flex: 0 0 auto;
    }
  }
  .form-control {
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid rgba(lighten($footer-bg, 20%), 0.74);
    padding-left: 0;
    height: 60px;
    font-size: 1.75rem;
    background-color: transparent;

    &:hover,
    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:focus {
      border-bottom-color: $primary;
    }
  }

  button[type="submit"] {
    border-radius: 0;
    font-weight: 500;
    padding-left: 24px;
    padding-right: 24px;
    text-transform: uppercase;
    background-color: rgba(lighten($footer-bg, 20%), 0.74);
    border: 0;
    font-size: 0.875rem;
  }

  .help-text {
    color: $footer-text-color;
    padding-top: 10px;
    font-weight: 300;
    font-size: 1rem;
    font-style: oblique;
  }
}

/* payment card styles */
.payment-card-options {
  font-size: 0;
  .img-area {
    margin: -2.5px;
  }
  img {
    display: inline-block;
    margin: 2.5px;
    border-radius: 2px;
  }

  h3 {
    font-size: 0.9375rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 0 10px;
    letter-spacing: 1px;
    color: $footer-headings-color;
  }
  @include media-breakpoint-up(lg) {
    text-align: right;
  }
}

/* Footer Contact styles */

#footer {
  .footer-contact {
    font-weight: 500;
    ul {
      padding-left: 10px;
      margin: 0;
      > li {
        list-style: none;
        position: relative;
        padding-left: 45px;
        font-size: 1rem;
        line-height: 1.5;
        & + li {
          margin-top: 15px;
        }
        i {
          position: absolute;
          left: 0;
          width: 30px;
          text-align: center;
          top: 3px;
          font-size: 1.375rem;
        }
        .small {
          display: block;
          font-size: 0.8375rem;
          font-weight: 400;
          opacity: 0.7;
        }
      }
    }
  }
}

/* footer nav  styles */

#footer .footer-nav {
  ul {
    padding: 0;
    margin-top: -6px;
    margin-bottom: -6px;

    > li {
      list-style: none;
      padding-top: 3px;
      padding-bottom: 3px;
      a {
        position: relative;
        display: inline-block;
        letter-spacing: 0.5px;
        font-weight: 300;

        &:hover {
          color: $footer-link-hover-color;
        }
      }
    }
  }

  &.width2,
  &.width3 {
    ul {
      @include make-row();
      > li {
        @include make-col-ready();
      }
    }
  }

  @include media-breakpoint-up(md) {
    &.width2 ul > li {
      flex: 0 0 50%;
      max-width: 50%;
    }
    &.width3 ul > li {
      flex: 0 0 33.33333%;
      max-width: 0 0 33.33333%;
    }
  }

  @include media-breakpoint-down(sm) {
    &.width3 ul > li,
    &.width2 ul > li {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

/* footer bottom styles */
#footer {
  .footer-bottom {
    font-size: 0.9375rem;
    position: relative;
    font-weight: 500;

    a {
      color: $primary;
    }

    &.bg-white {
      color: $body-color;
    }
  }
}

/* social media styles */
#footer {
  .social-media {
    a {
      color: $white;
    }
    @include media-breakpoint-up(md) {
      justify-content: flex-end;
    }
  }
}

/* footer styles on mobile and tablet */

@include media-breakpoint-down(md) {
  #footer {
    .legal-text {
      order: 2;
      padding-top: 15px;
    }
  }
}
