/* ql Editor  block style */

.ql-toolbar.ql-snow{
  border: 2px solid $border-color;
  background-color: $white;
  padding: 12px 10px;
}
.ql-container{
  height: calc(100% - 80px);
  min-height: 250px;
  max-height: 700px;
}
.ql-container.ql-snow{
  border: 2px solid $border-color;
  border-top: 0;
  background-color: $white;
  
}