/* Masonry style */
.masonry{
    &.masonry-grid {
        &.grid-item:after {
            content: '';
            display: block;
            clear: both;
        }
        img {
            min-height: 100px;
        }
        &.grid-3 {
            .grid-item,
            .grid-sizer {
                width: calc(100% / 3);
                &.width2 {
                    width: calc((100% / 3) * 2);
                }
                @include media-breakpoint-down(md) {
                    width: 50%;
                    &.width2 {
                        width: 50%;
                    }
                }
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    &.width2 {
                        width: 100%;
                    }
                }
            }
        }
        &.grid-4 {
            .grid-item,
            .grid-sizer {
                width: 25%;
                &.width2 {
                    width: 50%;
                }
                @include media-breakpoint-down(md) {
                    width: 50%;
                }
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    &.width2 {
                        width: 100%;
                    }
                }
            }
        }
        &:not(.no-gutter) {
            margin: $grid-gutter-width / -2;
            .grid-item {
                padding: $grid-gutter-width /2;
            }
        }
        &.line-gutter {
            margin: -.5px;
            .grid-item {
                padding: .5px;
            }
        }
    }
}