/* Social media icons style */

.social-media {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  a {
    text-align: center;
    color: rgba($body-color, 0.6);
    transition: 0.3s linear all;

    i {
      display: table;
      width: 100%;
      height: 100%;

      &:before {
        display: table-cell;
        vertical-align: middle;
      }
    }

    &:hover,
    &:focus {
      text-decoration: none;
      transform: translateY(-5px);
    }
  }

  &:not(.outline):not(.brand-color-bg) {
    a {
      @include font-size(1.25rem);
      & + a {
        margin-left: 15px;
      }
      &:hover,
      &:focus {
        color: $primary;
      }
    }
  }

  &.outline,
  &.brand-color-bg {
    a {
      height: 32px;
      width: 32px;
      & + a {
        margin-left: 8px;
      }
      @include font-size(1rem);
    }
  }
}

.text-center {
  .social-media {
    justify-content: center;
  }
}

/* Social media icons brand color style */
.social-media {
  &.brand-color {
    a {
      &.facebook {
        color: #3a559f;
      }

      &.twitter {
        color: #00bff6;
      }

      &.linkedin {
        color: #007ab9;
      }

      &.pinterest {
        color: #c52228;
      }

      &.instagram {
        color: #d02971;
      }
    }
  }
}

/* Social media icons outline style */
.social-media {
  &.outline {
    a {
      border: 1px solid $border-color;

      &:hover,
      &:focus {
        background-color: $primary;
        color: $white;
      }
    }
  }
}

/* Social media icons brand color outline style */
.social-media {
  &.brand-color.outline {
    a {
      &.facebook {
        border-color: #3a559f;

        &:hover,
        &:focus {
          background-color: #3a559f;
        }
      }

      &.twitter {
        border-color: #00bff6;

        &:hover,
        &:focus {
          background-color: #00bff6;
        }
      }

      &.linkedin {
        border-color: #007ab9;

        &:hover,
        &:focus {
          background-color: #007ab9;
        }
      }

      &.pinterest {
        border-color: #c52228;

        &:hover,
        &:focus {
          background-color: #c52228;
        }
      }

      &.instagram {
        border-color: #d02971;

        &:hover,
        &:focus {
          background-color: #d02971;
        }
      }

      &:hover,
      &:focus {
        color: $white;
      }
    }
  }
}

/* Social media icons brand color outline style */
.social-media {
  &.brand-color-bg {
    a {
      color: $white;

      &.facebook {
        background-color: #3a559f;
        border-color: #3a559f;
      }

      &.twitter {
        background-color: #00bff6;
        border-color: #00bff6;
      }

      &.linkedin {
        background-color: #007ab9;
        border-color: #007ab9;
      }

      &.pinterest {
        border-color: #c52228;
        background-color: #c52228;
      }

      &.instagram {
        border-color: #d02971;
        background-color: #d02971;
      }
    }
  }
}
